var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "ui-urlInText" },
    [
      _vm._l(_vm.texts, function(text, key) {
        return [
          _c("span", { key: key, domProps: { innerHTML: _vm._s(text) } }),
          key < _vm.texts.length
            ? [
                _c(
                  "button",
                  {
                    key: key + "_",
                    on: {
                      click: function($event) {
                        return _vm.gotoWeb(_vm.urls[key])
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.urls[key]))]
                )
              ]
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }